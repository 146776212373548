import "./App.css";
import React from "react";
import Carousel from "react-material-ui-carousel";
import { Button, Grid, Typography } from "@mui/material";
import css from "./css-logo.png";
import express from "./express.png";
import git from "./github.png";
import heroku from "./heroku.png";
import html from "./html.png";
import javascript from "./javascript.png";
import mongo from "./mongodb.png";
import node from "./nodejs (1).png";
import pythonLogo from "./python.png";
import reactLogo from "./react.png";
import postgresql from "./postgresql.png";
import materialUI from "./PngItem_5779757.png";
import django from "./django.png";
import insomnia from "./insomnia-logo-A35E09EB19-seeklogo.com.png";
import postman from "./2893aede23f01bfcbd2319326bc96a6ed0524eba759745ed6d73405a3a8b67a8.png";
import visualstudio from "./Visual-Studio-Logo.png";
import trello from "./kisspng-portable-network-graphics-trello-scalable-vector-g-5c025368ae6bb9.4395252315436562967144-removebg-preview.png";
import slack from "./SDDw7CnuoUGax6x9mTo7dd-removebg-preview.png";
import netlify from "./logomark.png";
import tableplus from "./apple-icon.png";

import first from "./first.png";
import second from "./secondd.png";
import third from "./third.png";
import fourth from "./fourthh.png";
import email from "./email.png";
import linkedin from "./linkedin.png";
import github from "./github copy.png";

import iac from "./intarb_copy-removebg-preview.png";
import investcloud from "./investcloud-logo.png"
import orangesq from "./logo-removebg-preview.png";
import emirates from "./download__1_-removebg-preview.png";
import ga from "./download-removebg-preview (1).png";

import claudia from "./claudia-logo.png";

import judo from "./judo-removebg-preview.png";
import food from "./pngwing.com (2).png";
import plane from "./pngwing.com (1).png";

function App(props) {
  let items = [
    {
      name: "Flying Harry Potter Game",
      description:
        "Solo - 2 weeks - I decided to build a canvas based game using Vanilla JavaScript, HTML and CSS.It follows the basic logic of avoiding obstacles and collecting trophies to win points.",
      image: first,
      link: "https://flying-harry-potter.netlify.app/",
      gitlink:
        "https://github.com/claudia-pacheco/Project-1/blob/master/README.md",
    },
    {
      name: "Dungeons & Dragons Character Builder",
      description:
        "Pair-programming - 1 week - Reactathon project that consumes a public API to create a random character generator. Styling was done using CSS. Initially, we tried to code along together and found it quite slow and unproductive. Therefore, we then split our work load by logic and styling, where I was assigned the logic.",
      image: second,
      link: "https://dungeons-n-dragons.netlify.app",
      gitlink:
        "https://github.com/claudia-pacheco/project-2/blob/main/README.md",
    },
    {
      name: "Walkies",
      description:
        "Pair-programming - 2 weeks - Full Stack MERN app that consumes its own RESTful API. Built using React, HTML and CSS for Frontend and MongoDB, Node.js and Express for Backend. Backend was done together by coding along with a shared screen and Frontend was split.I coded Register/ Messages / Dog.",
      image: third,
      link: "https://walkiessei22.netlify.app/",
      gitlink:
        "https://github.com/claudia-pacheco/walkies-client/blob/main/README.md",
    },
    {
      name: "Cloud9 Scents",
      description:
        "Solo - 2 weeks - Full Stack app consuming a Python Django API using Django REST Framework to serve data from a Postgres database.Built using React and Material UI for styling.",
      image: fourth,
      link: "https://cloud9-scents.netlify.app/",
      gitlink:
        "https://github.com/claudia-pacheco/perfumes-frontend/blob/main/README.md",
    },
  ];
  function Item(props) {
    return (
      <>
        <h2>{props.item.name}</h2>
        <img src={props.item.image} alt="project" height="300"></img>
        <p>{props.item.description}</p>
        <Button sx={{ color: "#553b27" }} href={props.item.link}>
          Check it out!
        </Button>
        or
        <Button sx={{ color: "#553b27" }} href={props.item.gitlink}>
          ReadMe
        </Button>
      </>
    );
  }

  return (
    <>
      <header>
        <nav>
          <img className="logo-cp" src={claudia} alt="claudia-pacheco-logo"></img>
          <ul>
            <li>
              <a href="#about-me">About Me</a>
            </li>
            <li>
              <a href="#skills">Skills</a>
            </li>
            <li>
              <a href="#projects">Projects</a>
            </li>
            <li>
              <a href="#experience">Experience</a>
            </li>
            <li>
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </nav>
      </header>

      <section className="hello">
        <h2> Hi, everyone!</h2>
        <h3>
          Welcome to my (<i>coding</i>) world. Browse my webpage to know what I
          get up to &amp; feel free to get in touch.
        </h3>
        <h5>I'm currently updating this portfolio with new info and design!
        </h5>
      </section>

      <div className="first">
        <Grid container spacing={2} columns={16}>
          <Grid item xs={12} sm={8}>
            <section id="about-me" className="intro">
              <h1>About me</h1>
              <Typography>
                <p>
                  I'm a Software Engineer with a lifelong passion for technology that sparked during my school days in a robotics club. Designing and programming a robot ignited my interest in fundamental programming concepts, inspiring me to pursue a Computer Science degree. To enhance my skills, I joined a fast-paced coding bootcamp at General Assembly, gaining valuable experience in collaborative teamwork. Currently I'm a proud Front End Developer in the Financial Services industry, continually growing in the dynamic world of software development.
                </p></Typography>
              <h2>Other passions</h2>
              <img src={judo} alt="judo" height="60px"></img>
              <h4>Martial Arts</h4>
              <Typography ><p>
                I am a green belt in Judo and participated in several
                competitions in Portugal as a kid.
              </p></Typography>
              <img src={plane} alt="plane" height="60px"></img>
              <h4>Travelling</h4>
              <p>
                {" "}
                Meeting new cultures and exploring new places is one of my
                favourite hobbies. Hopefully South America is next on the list.
              </p>
              <img src={food} alt="food" height="60px"></img>
              <h4>Food</h4>
              <p>
                I&apos;m a big foodie. Whether it&apos;s eating, cooking or
                baking I&apos;m all up for it! I enjoy different kinds of
                cuisines, so food festivals are perfect to try a bit of
                everything.
              </p>
            </section>
          </Grid>

          <Grid item xs={12} sm={8}>
            <section id="skills" className="skills">
              <h1>Skills</h1>

              <div className="logos">
                <li>
                  <img src={javascript} alt=" javascript" height="80px"></img>
                  <h2>JavaScript</h2>
                </li>
                <li>
                  <img src={reactLogo} alt="reactLogo " height="80px"></img>
                  <h2>React</h2>
                </li>
                <li>
                  <img src={pythonLogo} alt="pythonLogo " height="80px"></img>
                  <h2>Python</h2>
                </li>
                <li>
                  <img src={django} alt=" django" height="80px"></img>
                  <h2>Django</h2>
                </li>
                <li>
                  <img src={html} alt=" html" height="80px"></img>
                  <h2>HTML</h2>
                </li>
                <li>
                  <img src={css} alt=" css" height="80px"></img>
                  <h2>CSS</h2>
                </li>
                <li>
                  <img src={node} alt=" nodejs" height="80px"></img>
                  <h2>Node.js</h2>
                </li>
                <li>
                  <img src={mongo} alt=" mongodb" height="80px"></img>
                  <h2>MongoDB</h2>
                </li>
                <li>
                  <img src={express} alt=" express" height="80px"></img>
                  <h2>Express</h2>
                </li>
                <li>
                  <img src={postgresql} alt="postgresql " height="80px"></img>
                  <h2>PostgreSQL</h2>
                </li>
                <li>
                  <img src={tableplus} alt=" tableplus" height="80px"></img>
                  <h2>TablePlus</h2>
                </li>
                <li>
                  <img src={insomnia} alt=" insomnia" height="80px"></img>
                  <h2>Insomnia</h2>
                </li>
                <li>
                  <img src={postman} alt=" postman" height="80px"></img>
                  <h2>Postman</h2>
                </li>
                <li>
                  <img src={materialUI} alt="materialUI " height="80px"></img>
                  <h2>MaterialUI</h2>
                </li>
                <li>
                  <img src={git} alt="git-hub" height="80px"></img>
                  <h2>GitHub</h2>
                </li>
                <li>
                  <img src={heroku} alt="heroku " height="80px"></img>
                  <h2>Heroku</h2>
                </li>{" "}
                <li>
                  <img src={slack} alt=" slack" height="80px"></img>
                  <h2>Slack</h2>
                </li>
                <li>
                  <img src={netlify} alt=" netlify" height="80px"></img>
                  <h2>Netlify</h2>
                </li>
                <li>
                  <img src={trello} alt=" trello" height="80px"></img>
                  <h2>Trello</h2>
                </li>
                <li>
                  <img
                    src={visualstudio}
                    alt=" visualstudio"
                    height="80px"
                  ></img>
                  <h2>Visual Studio Code</h2>
                </li>
              </div>
            </section>
          </Grid>
        </Grid>
      </div>

      <section id="projects" className="projects">
        <h1>Projects</h1>
        <div className="carousel">
          {" "}
          <Carousel
            autoPlay={false}
            navButtonsAlwaysVisible={true}
            sx={{ width: "1150px", height: "500px" }}
          >
            {items.map((item, i) => (
              <Item key={i} item={item} />
            ))}
          </Carousel>
        </div>
      </section>

      <section id="experience" className="jobs">
        <h1>Experience</h1>
        <div className="experience">
          <Grid container spacing={10} columns={8}>
            <Grid item xs={2}>
              <img src={investcloud} alt="investcloud-logo" height="100px"></img>
              <h2>Frontend Developer</h2>
              <h4>InvestCloud, Jun 2023 - Present </h4>
              <p>
                Created interactive digital interfaces using proprietary technology and visual design tools, adhering to design guidelines.
                Developed web functionality and optimised UX workflows.
                Build screens for tablet and phone applications.
                Worked closely with Project Managers and Business Analysts to meet project goals. Build and conduct testing to identify discrepancies. Delivered comprehensive functionality documentation to QA and support teams.
              </p>
            </Grid>
            <Grid item xs={2}>
              <img src={iac} alt="IAC-logo" height="100px"></img>
              <h2>FOH &amp; Virtual Hearings</h2>
              <h4>The International Arbitration Centre, Jun 2021 - June 2023 </h4>
              <p>
                Dealt with high profile customers in a hospitality environment
                within the Legal industry. Responsible for setting up and
                running Virtual Hearings through Zoom as well as in the
                company&apos;s dashboard using WordPress. Delivered familiarisation sessions to
                clients as well as training new staff members.
              </p>
            </Grid>
            <Grid item xs={2}>
              <img src={ga} alt="GA-logo" height="100px"></img>
              <h2>Software Engineering Immersive Flex Part-time Student</h2>
              <h4>General Assembly, Nov 2021 - May 2022</h4>
              <p>
                Completed 24 weeks of expert-led Zoom classes covering several
                languages and frameworks with an emphasis on React, JavaScript,
                HTML, CSS, Node.js, Express, SQL and NoSQL databases, and
                Python. We also had daily stand-ups, labs, pair-programming and
                4 projects covering both Frontend and Backend.
              </p>
            </Grid>
            <Grid item xs={2}>
              <img src={emirates} alt="Emirates-logo" height="100px"></img>
              <h2>Flight Attendant</h2>
              <h4>Emirates, Jan 2020 - Jun 2020 </h4>
              <p>
                High standard of customer service on board and whilst on ground.
                Extensive and intensive training on both customer service and
                security. Successfully adapted to different cultures and social
                differences as the job was based in cosmopolitan Dubai.
              </p>
            </Grid>
            <Grid item xs={2}>
              <img src={orangesq} alt="Orange-Square-Logo" height="100px"></img>
              <h2>Sales Consultant</h2>
              <h4>The Orange Square Company, Mar 2016 - Nov 2019</h4>
              <p>
                Fragrance specialist of 5 luxury brands (Creed, Maison Francis
                Kurkdjian, Mizensir, Juliette Has a Gun, Parle Moi de Parfum)
                based in Selfridges London Flagship store. Delivered excellent
                customer service, CRM retention and dealt with high profile
                clients. Performed basic admin work and led/managed the agency
                team. Till proficient and in charge of opening/closing duties.
              </p>
            </Grid>
          </Grid>
        </div>
      </section>

      <section id="contact" className="contact">
        <h1>Contact</h1>
        <div className="contacts">
          <li>
            <img alt=" " src={email} width="35px"></img>
            <a href="mailto:claudia.pacheco.dev@gmail.com">
              claudia.pacheco.dev@gmail.com
            </a>
          </li>
          <br />
          <li>
            {" "}
            <img src={linkedin} alt=" " width="35px"></img>
            <a href="https://www.linkedin.com/in/claudia-pacheco1/">
              linkedin.com/in/claudia-pacheco1
            </a>
          </li>
          <br />
          <li>
            {" "}
            <img src={github} alt=" " width="35px"></img>
            <a href="https://github.com/claudia-pacheco">
              github.com/claudia-pacheco
            </a>
          </li>
        </div>
      </section>
    </>
  );
}

export default App;
